<template>
  <dashboard>
    <el-row :gutter="15">
      <el-col :xs="24">
        <el-card class="transactions" :body-style="{ padding: '0px' }">
          <h4 class="mx-3 mt-3 mb-1 bold orange-text">My Transactions</h4>
          <p class="mx-3 small">Here is a history of your payments.</p>  
          <hr>
          <div v-if="!actions.isLoading && invoices == 0" class="px-3 py-4 text-center">
            <p class="mb-3 small">You currently have no transactions.</p>
            <router-link to="/employer/billing">
              <el-button type="success">Upgrade subscription</el-button>
            </router-link> 
          </div>
          <div v-else class="m-3">
            <el-table v-loading="actions.isLoading" :data="invoices" stripe style="width: 100%" class="mb-3">
                <el-table-column v-for="col in columns" :key="col.prop" :prop="col.prop" :label="col.label" :width="col.width" >
                </el-table-column>
              <el-table-column width="180">
                <template slot-scope="scope">
                  <el-button @click="downloadInvoice(scope.$index)" type="text" size="small" class="orange-text">Download Invoice</el-button>
                </template>
              </el-table-column>
            </el-table>
            <!-- <div class="visible-xs mobile-view">
              <div class="transaction-row">
                <p class="mb-2 bold">Invoice for the month of January 2020</p>
                <h6 class=""><span class="orange-text">Date:</span> 2016-0-01</h6>
                <h6 class="mb-3"><span class="orange-text">Amount:</span> $69</h6>
                <el-button @click="downloadInvoice(scope.row)" type="warning" size="mini">Download Invoice</el-button>
              </div>
              <div class="transaction-row">
                <p class="mb-2 bold">Invoice for the month of February 2020</p>
                <h6 class=""><span class="orange-text">Date:</span> 2016-02-01</h6>
                <h6 class="mb-3"><span class="orange-text">Amount:</span> $69</h6>
                <el-button @click="downloadInvoice(scope.row)" type="warning" size="mini">Download Invoice</el-button>
              </div>
              <div class="transaction-row">
                <p class="mb-2 bold">Invoice for the month of March 2020</p>
                <h6 class=""><span class="orange-text">Date:</span> 2016-03-01</h6>
                <h6 class="mb-3"><span class="orange-text">Amount:</span> $69</h6>
                <el-button @click="downloadInvoice(scope.row)" type="warning" size="mini">Download Invoice</el-button>
              </div>
            </div> -->
          </div>
        </el-card>
      </el-col>
    </el-row>
  </dashboard>
</template>

<script>
  import Dashboard from '~/components/Dashboard/Dashboard'
  import axios from "axios";
  import {LOCAL_BASE_URL, EMPLOYER} from '../../vuex/utils/constant';
  import {mapState, mapActions} from "vuex";
  import moment from 'moment'

  export default {
    components: {
      Dashboard,
    },
    created() {
      this.$store.dispatch('loadEmployerTransactions')
    },
    data () {
      return {
        value: 4.5,

        columns: [
          {
            prop:"date",
            label: "Date",
            width: "200"
          },
          {
            prop:"amount",
            label: "Amount",
            width: "100"
          },
          {
            prop:"details",
            label: "Details",
          },
        ],
        invoices: [],
        actions: {
          isLoading: false
        }
        // transactions: [{
        //   date: '2016-01-01',
        //   amount: '$69',
        //   details: 'Invoice for the month of January 2020'
        // }, {
        //   date: '2016-02-01',
        //   amount: '$69',
        //   details: 'Invoice for the month of February 2020'
        // }, {
        //   date: '2016-03-01',
        //   amount: '$69',
        //   details: 'Invoice for the month of March 2020'
        // }, {
        //   date: '2016-04-01',
        //   amount: '$69',
        //   details: 'Invoice for the month of April 2020'
        // }]
      }
    },
    computed: {
      ...mapState(['transactions', 'isLoaded'])
    },
     methods: {
      ...mapActions([
        'getInvoices'
      ]),

       formatFileName(row) {
         let receiptDate =
                 this.transactions.transactions.invoice[row].receipt_date.split('T')[0];
        return `${receiptDate}-citronworks-invoice-${this.transactions.transactions.invoice[row].receipt_number}.pdf`;
       },

      downloadInvoice(row) {
        let user = JSON.parse(localStorage.getItem('user'));
        axios.get(LOCAL_BASE_URL + EMPLOYER + '/invoices/download/' + this.transactions.transactions.invoice[row].receipt_id , {
          params: {
            member_id: this.transactions.transactions.member_id
          },
          headers: {
            Authorization: 'Bearer ' + user.authorization.access_token,
            'Content-Type': 'application/json',
          },
          responseType: 'blob',
          Accept: 'application/pdf'
        })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download',
                    this.formatFileName(row));
            document.body.appendChild(link);
            link.click();
          })
      }
    },
    mounted () {
      this.actions.isLoading = true
      Promise.all([this.getInvoices()])
        .then(d => {
          this.actions.isLoading = false
          if (d[0].data.transactions) {
            this.invoices = d[0].data.transactions.invoice.map(m => ({
              date: moment(m.receipt_date).format('MMMM D, YYYY'),
              amount: '$' + m.amount,
              details: `Invoice for the month of ${moment(m.receipt_date).format('MMMM YYYY')}`
            }))
          }
        })
          .catch(() => {
            this.actions.isLoading = false
          })
    }
  }
</script>
