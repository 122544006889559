<template>
  <dashboard>
    <div class="mb-4 ml-1">
      <h4 class="mb-1 bold orange-text">My Transactions</h4>
      <p>Here is a history of your payments in Citronworks.</p>
    </div>

    <hr>
    <el-row :gutter="15">
      <el-col :xs="24">
        <el-card class="transactions">
          <el-table :data="transactions" border style="width: 100%">
              <el-table-column v-for="col in columns" :key="col.prop" :prop="col.prop" :label="col.label" :width="col.width" >
              </el-table-column>
            <el-table-column width="180">
              <template slot-scope="scope">
                <el-button @click="downloadInvoice(scope.row)" type="text" size="small" class="orange-text">Download Invoice</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>
  </dashboard>
</template>

<script>
  import Dashboard from '~/components/Dashboard/Dashboard'
  import axios from "axios";
  import {LOCAL_BASE_URL, FREELANCER } from '../../vuex/utils/constant';
  import {mapState} from "vuex";

  export default {
    components: {
      Dashboard,
    },
    created() {
      this.$store.dispatch('loadFreelancerTransactions')
    },
    data () {
      return {
        value: 4.5,
        columns: [
          {
            prop:"date",
            label: "Date",
            width: "100"
          },
          {
            prop:"amount",
            label: "Amount",
            width: "90"
          },
          {
            prop:"details",
            label: "Details",
          },
        ],
      }
    },
    computed: {
      ...mapState(['transactions', 'isLoaded'])
    },
     methods: {
      downloadInvoice(row) {
        axios.get(LOCAL_BASE_URL + FREELANCER + '/invoices/download/' + row.id , {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('access_token'),
          },
          responseType: 'blob',
          Accept: 'application/pdf'
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${row.id}_${row.date}.pdf`);
          document.body.appendChild(link);
          link.click();
        })
      }
    },
  }
</script>

<style lang="scss">

</style>
